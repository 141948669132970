.body-container {
  min-height: calc(100vh - 100px);
}

.container.main-content {
  margin-top: 80px;
}

@media only screen and (max-width: 1088px) {
    .container.main-content {
        padding: 0 20px;
    }
}

.menu .menu-list a {
  transition: all 0.15s ease;
  border: 1px solid transparent;
}
.menu .menu-list a:hover {
  background-color: #e9e9e9;
}
.menu .menu-list a.active{
  border-color: #00aa46;
  border-style: solid;
  border-width: 1px 1px 1px 7px;
  border-radius: 3px;
  color: #000;
}
.menu .menu-list a.active:hover {
  background-color: transparent;
}

.service-description {
    margin-top: 30px;
    color: #424242;
}
.service-description p {
    padding: 7px 0;
}

.is-open-color,
.tag.is-open-color {
    background-color: #1eb352;
    color: #fff;
}

.is-closed-color,
.tag.is-closed-color {
    background-color: #e45555;
    color: #ffffff;
}

.footer {
    background-color: #1f1f1f;
    color: #707070;
    height: 80px;
    text-align: center;
    font-size: 12px;
    margin-top: 40px;
    box-sizing: border-box;
    padding: 30px 0 0 0;
}

.menu-label {
    font-size: 16px;
}