body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: -webkit-linear-gradient(309deg, #f7f7f7 0, #f2f2f2 71%, #fff 100%);

  background-image: linear-gradient(141deg, #f7f7f7 0, #f2f2f2 71%, #fff 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  /*max-width: 960px !important;*/
}

.body-container {
  min-height: calc(100vh - 100px);
}

.container.main-content {
  margin-top: 80px;
}

@media only screen and (max-width: 1088px) {
    .container.main-content {
        padding: 0 20px;
    }
}

.menu .menu-list a {
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: 1px solid transparent;
}
.menu .menu-list a:hover {
  background-color: #e9e9e9;
}
.menu .menu-list a.active{
  border-color: #00aa46;
  border-style: solid;
  border-width: 1px 1px 1px 7px;
  border-radius: 3px;
  color: #000;
}
.menu .menu-list a.active:hover {
  background-color: transparent;
}

.service-description {
    margin-top: 30px;
    color: #424242;
}
.service-description p {
    padding: 7px 0;
}

.is-open-color,
.tag.is-open-color {
    background-color: #1eb352;
    color: #fff;
}

.is-closed-color,
.tag.is-closed-color {
    background-color: #e45555;
    color: #ffffff;
}

.footer {
    background-color: #1f1f1f;
    color: #707070;
    height: 80px;
    text-align: center;
    font-size: 12px;
    margin-top: 40px;
    box-sizing: border-box;
    padding: 30px 0 0 0;
}

.menu-label {
    font-size: 16px;
}
.navbar {
    background-color: #1f1f1f;
}

.navbar-item img {
    max-height: 48px;
    margin: 7px 12px 7px 0;
}

.website-name {
    font-size: 1.9rem;
    text-transform: uppercase;
    letter-spacing: -4px;
    font-weight: 400;
    color: #fff;
}

.website-tld {
    color: #8f8f8f;
}

.country-flag-header {
    margin-top: -4px;
    margin-right: 4px;
}
.quick-browser-info {
    background: none;
}

.info-title {
    color: #aaa;
    margin: 2px 0 4px;
    text-align: left;
}

.info-value {
    color: #000;
    text-align: right;
}

.info-item-wrapper {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 10px;
}

.info-item-wrapper:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.ip-tag {
    min-width: 120px;
}

.tag.ip-copy-btn {
    cursor: pointer;
    color: #777;
    background-color: #ffffff;
}

.tag.ip-copy-btn:hover {
    color: #000;
}

.tag.ip-copy-btn.is-copied {
    pointer-events: none;
    color: #aaa;
}

.country-flag {
    margin-right: 10px;
}

.copy-icon {
    height: 16px;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    opacity: 1;
}

.copy-icon.copied {
    opacity: 0.2;
}
.service-block {
    margin: 15px 0;
    box-sizing: content-box;
    background-color: #e9e9e9;
    border-radius: 4px;
    min-height: 180px;
    position: relative;
}

.error-message {
    position: absolute;
    top: 15px;
    font-weight: bold;
}

.service-block .action-block {
    margin-top: 32px;
}

.port-input:focus:not(.is-danger) {
    border-color: #b5b5b5;
    box-shadow: 0 0 0 1px rgba(48, 103, 203, 0.25);
}

.port-input[disabled] {
    border-color: #dbdbdb;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}


.result-block {
    position: absolute;
    bottom: 20px;
    box-sizing: border-box;
}

.result-port-number {
    margin-right: 4px;
}

.result-port-protocol {
    margin-right: 4px;
    color: #e0e0e0;
}
table.port-table tr>td:first-child {
     color: #b5b5b5;
}

table.port-table {
    counter-reset: rowNumber;
}

table.port-table tbody tr {
    counter-increment: rowNumber;
}

table.port-table tr td:first-child::before {
    content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
}

.result-list {
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.tags.port-result-tag {
    min-width: 180px;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 5px 7px;
}

.tags.port-result-tag .grow {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.tags.port-result-tag > .tag:last-child {
    min-width: 55px;
}
.header-value {
    word-break: break-word;
}

.request-uri {
    margin: 15px 0;
    color: #878787;
    font-size: 20px;
    word-break: break-all;
}

.result-text-block hr {
    margin: 10px 0;
}

.tag-response-code {
    margin-top: 2px;
}

.redirect-chain-block {
    margin: 10px 0;
}

.redirect-chain-item {
    margin: 2px 0;
}

.redirect-uri {
    word-break: break-all;
    margin-left: 8px;
}
.result-text {
    white-space: pre-line;
    max-height: 800px;
}

.result-text-block {
    max-width: 90%;
    margin-bottom: 20px;
}
