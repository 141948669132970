.port-input:focus:not(.is-danger) {
    border-color: #b5b5b5;
    box-shadow: 0 0 0 1px rgba(48, 103, 203, 0.25);
}

.port-input[disabled] {
    border-color: #dbdbdb;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}


.result-block {
    position: absolute;
    bottom: 20px;
    box-sizing: border-box;
}

.result-port-number {
    margin-right: 4px;
}

.result-port-protocol {
    margin-right: 4px;
    color: #e0e0e0;
}