.service-block {
    margin: 15px 0;
    box-sizing: content-box;
    background-color: #e9e9e9;
    border-radius: 4px;
    min-height: 180px;
    position: relative;
}

.error-message {
    position: absolute;
    top: 15px;
    font-weight: bold;
}

.service-block .action-block {
    margin-top: 32px;
}
