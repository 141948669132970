@import '~bulma-tooltip';

.quick-browser-info {
    background: none;
}

.info-title {
    color: #aaa;
    margin: 2px 0 4px;
    text-align: left;
}

.info-value {
    color: #000;
    text-align: right;
}

.info-item-wrapper {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 10px;
}

.info-item-wrapper:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.ip-tag {
    min-width: 120px;
}

.tag.ip-copy-btn {
    cursor: pointer;
    color: #777;
    background-color: #ffffff;
}

.tag.ip-copy-btn:hover {
    color: #000;
}

.tag.ip-copy-btn.is-copied {
    pointer-events: none;
    color: #aaa;
}

.country-flag {
    margin-right: 10px;
}

.copy-icon {
    height: 16px;
    transition: opacity 0.2s ease;
    opacity: 1;
}

.copy-icon.copied {
    opacity: 0.2;
}